import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';

import { forgotPasswordEmail } from '../../redux/userSlice';
// @mui
import { Stack, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

// ----------------------------------------------------------------------

export default function ForgotForm() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email('Wrong email format')
			.min(5, 'Minimum 5 symbols')
			.max(40, 'Maximum 40 symbols')
			.required('Email is required'),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: LoginSchema,

		onSubmit: async (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setTimeout(() => {
				forgotPassword(values.email)
					.then((res) => {
						if (
							res?.data?.status === 200 &&
							res?.data?.data?.role === 'admin'
						) {
							disableLoading();
							enqueueSnackbar('Email successfully sent!', {
								variant: 'success',
							});
							dispatch(forgotPasswordEmail(values.email));

							navigate('/verify-code', { replace: true });
						} else {
							disableLoading();
							enqueueSnackbar('Invalid email!', {
								variant: 'error',
							});
						}
					})
					.catch(() => {
						disableLoading();
						setSubmitting(false);
						setStatus(false);
					});
			}, 1500);
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={3}>
					<TextField
						id='email'
						name='email'
						type='email'
						placeholder='Email address'
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
				</Stack>

				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={{ my: 2 }}
				></Stack>

				<LoadingButton
					fullWidth
					size='large'
					type='submit'
					variant='contained'
				>
					{loading ? (
						<span>
							<CircularProgress size='1.5rem' color='inherit' />
						</span>
					) : (
						<Box> Reset Password</Box>
					)}
				</LoadingButton>
			</form>
		</>
	);
}
