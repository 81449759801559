// @mui
import { Grid, Stack } from '@mui/material';
//
import ProfileAbout from './ProfileAbout';
import ProfileFollowInfo from './ProfileFollowInfo';
import ProfileSocialInfo from './ProfileSocialInfo';

// ----------------------------------------------------------------------

export default function Profile({ myProfile }) {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<Stack spacing={3}>
					<ProfileFollowInfo profile={myProfile} />
					<ProfileAbout profile={myProfile} />
					<ProfileSocialInfo profile={myProfile} />
				</Stack>
			</Grid>
		</Grid>
	);
}
