import { useState, useEffect } from 'react';
// @mui
import { Box, Card, Container } from '@mui/material';

import useTabs from '../../hooks/useTabs';

// import { _userAbout } from '../../_mock/_user';

import { trainingDetail } from '../../../src/api';

// components
import Page from '../../components/Page';
import Iconify from '../../components/iconify';

// sections
import {
	Training,
	TrainingCover,
	TrainingAbout,
} from '../../../src/sections/@dashboard/training';

import { getUserCookie } from '../../utils/getCookies';
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { getTrainingDetail } from '../../redux/userSlice';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function TrainingDetail() {
	const { currentTab, onChangeTab } = useTabs('training');
	const trainingId = useSelector((state) => state.user.trainingId);

	console.log('trainingId...', trainingId);

	const [trainingDetailData, setTrainingDetailData] = useState([]);
	const cookie = getUserCookie();
	const dispatch = useDispatch();

	useEffect(() => {
		async function fetchTrainingDetail() {
			// You can await here
			const response = await trainingDetail(cookie, trainingId);
			if (response && response?.data?.data) {
				setTrainingDetailData(response?.data?.data);
				dispatch(getTrainingDetail(response?.data?.data));
			}
		}
		fetchTrainingDetail();
	}, [trainingId]);

	console.log('trainingDetailData....', trainingDetailData);

	return (
		<Page title='Training: Detail'>
			<Container>
				<Card
					sx={{
						mb: 3,
						height: 280,
						position: 'relative',
					}}
				>
					<TrainingCover training={trainingDetailData} />
				</Card>

				<Card
					sx={{
						mb: 3,
						height: 'auto',
						position: 'relative',
					}}
				>
					<TrainingAbout training={trainingDetailData} />
				</Card>
			</Container>
		</Page>
	);
}
