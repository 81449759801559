import Api from '../../utils/axios';
const trainingDetail = async (token, trainingId) => {
	try {
		console.log('trainingId...', trainingId);
		return await Api.get(`/admin/training/${trainingId}`, {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default trainingDetail;
