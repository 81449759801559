// @mui
import { Card, Stack, Typography, Divider } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function ProfileFollowInfo({ profile }) {
	const { followersCount, followingCount } = profile;

	return (
		<Card sx={{ py: 3 }}>
			<Stack
				direction='row'
				divider={<Divider orientation='vertical' flexItem />}
			>
				<Stack width={1} textAlign='center'>
					<Typography variant='h4'>
						{fNumber(followersCount ? followersCount : 0)}
					</Typography>
					<Typography variant='body2' sx={{ color: 'text.secondary' }}>
						Followers
					</Typography>
				</Stack>

				<Stack width={1} textAlign='center'>
					<Typography variant='h4'>
						{fNumber(followingCount ? followingCount : 0)}
					</Typography>
					<Typography variant='body2' sx={{ color: 'text.secondary' }}>
						Following
					</Typography>
				</Stack>
			</Stack>
		</Card>
	);
}
