import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const storeCookies = (data) => {
	if (data) {
		const oneDay = 24 * 60 * 60 * 1000;
		cookies.set('jwt_token', data, {
			path: '/',
			sameSite: 'strict',
			expires: new Date(new Date().getTime() + oneDay),
			secure: true,
		});
	} else {
		console.log('Token not found for cookies');
	}
};
