import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Login } from "../../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { getUserData } from "../../../redux/userSlice";
import { storeCookies } from "../../../utils/storeCookies";

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(5, "Minimum 5 symbols")
      .max(40, "Maximum 40 symbols")
      .required("Email is required"),
    password: Yup.string()
      .min(5, "Minimum 5 symbols")
      .max(30, "Maximum 30 symbols")
      .required("Password is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        Login(values.email, values.password)
          .then((res) => {
            disableLoading();
            if (res.status === 200 && res?.data?.data?.role === "admin") {
              enqueueSnackbar("Succesfully login!", {
                variant: "success",
              });
              navigate("/dashboard", { replace: true });
              dispatch(getUserData(res?.data?.data));
              storeCookies(res?.data?.data?.token);
            } else {
              enqueueSnackbar("Invalid email or password!", {
                variant: "error",
              });
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(false);
          });
      }, 1500);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Box></Box>
          <Link
            sx={{ cursor: "pointer" }}
            component={""}
            variant="subtitle2"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          // disabled={formik.isSubmitting}
          variant="contained"
          // onClick={handleClick}
        >
          {loading ? (
            <span>
              <CircularProgress size="1.5rem" color="inherit" />
            </span>
          ) : (
            <Box>Login</Box>
          )}
        </LoadingButton>
      </form>
    </>
  );
}
