import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// api
import { editUser } from '../../../api';
import { getUserCookie } from '../../../utils/getCookies';

// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
// utils

import {
	FormProvider,
	RHFTextField,
	// RHFUploadAvatar,
} from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function UserNewEditForm({ isEdit, userData, userId }) {
	const navigate = useNavigate();
	const cookie = getUserCookie();

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		firstName: Yup.string().required('firstName is required'),
		lastName: Yup.string().required('lastName is required'),
		email: Yup.string().required('Email is required').email(),
		phoneNumber: Yup.string().required('Phone number is required'),
		city: Yup.string().required('city is required'),
	});

	const defaultValues = useMemo(
		() => ({
			firstName: userData?.firstName || '',
			lastName: userData?.lastName || '',
			email: userData?.user?.email || '',
			phoneNumber: userData?.phoneNumber || '',
			city: userData?.city || '',
			facebook: userData?.links?.facebook || '',
			instagram: userData?.links?.instagram || '',
			twitter: userData?.links?.twitter || '',
			role: userData?.user?.role || '',
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userId]
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,

		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		try {
			const response = await editUser(cookie, userId, data);
			if (
				response &&
				response?.data?.status === 200 &&
				response?.data?.data
			) {
				reset(defaultValues);
				enqueueSnackbar('User updated successfully!', {
					variant: 'success',
				});
				navigate('/dashboard/user');
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Box mb={2}>
				<Typography variant='h5'>Edit Profile</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={8}>
					<Card sx={{ p: 3 }}>
						<Box
							sx={{
								display: 'grid',
								columnGap: 2,
								rowGap: 3,
								gridTemplateColumns: {
									xs: 'repeat(1, 1fr)',
									sm: 'repeat(2, 1fr)',
								},
							}}
						>
							<RHFTextField name='firstName' label='First Name' />
							<RHFTextField name='lastName' label='Last Name' />
							<RHFTextField
								disabled={true}
								name='email'
								label='Email Address'
							/>
							<RHFTextField name='phoneNumber' label='Phone Number' />
							<RHFTextField name='city' label='City' />
							<RHFTextField disabled={true} name='role' label='Role' />
							<RHFTextField name='facebook' label='Facebook' />
							<RHFTextField name='twitter' label='Twitter' />
							<RHFTextField name='instagram' label='Instagram' />
						</Box>

						<Stack alignItems='flex-end' sx={{ mt: 3 }}>
							<LoadingButton
								size='large'
								type='submit'
								variant='contained'
								loading={isSubmitting}
							>
								<Box>Save Changes</Box>
							</LoadingButton>
						</Stack>
					</Card>
				</Grid>
			</Grid>
		</FormProvider>
	);
}
