import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';

import React, { useState } from 'react';
import {
	Avatar,
	Checkbox,
	TableRow,
	TableCell,
	Typography,
	MenuItem,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';

import Label from '../../../components/Label';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableMoreMenu } from '../../../components/table';
import { getTrainingId } from '../../../redux/userSlice';

// ----------------------------------------------------------------------

TrainingTableRow.propTypes = {
	row: PropTypes.object,
	selected: PropTypes.bool,
	onEditRow: PropTypes.func,
	onSelectRow: PropTypes.func,
	onDeleteRow: PropTypes.func,
};

export default function TrainingTableRow({ row, selected, onSelectRow }) {
	const theme = useTheme();
	let navigate = useNavigate();

	const dispatch = useDispatch();
	const [openMenu, setOpenMenuActions] = useState(null);

	const handleOpenMenu = (event) => {
		setOpenMenuActions(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpenMenuActions(null);
	};

	const { name, price, place, duration, eventType, trainingState } = row;

	return (
		<TableRow hover selected={selected}>
			<TableCell sx={{ display: 'flex', alignItems: 'center' }}>
				<Avatar alt={'avatar'} src={row?.photos?.[0]} sx={{ mr: 2 }} />
				<Typography variant='subtitle2' noWrap>
					{name}
				</Typography>
			</TableCell>

			<TableCell align='left'>{'€' + ' ' + price}</TableCell>

			<TableCell align='left'>{place}</TableCell>

			<TableCell align='left' sx={{ textTransform: 'capitalize' }}>
				{duration}
			</TableCell>

			<TableCell align='center'>
				<Label
					variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
					color={(eventType === 'physical' && 'primary') || 'warning'}
					sx={{ textTransform: 'capitalize' }}
				>
					{(eventType === 'physical' && 'physical') || 'online'}
				</Label>
			</TableCell>

			<TableCell align='left'>
				<Label
					variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
					color={(trainingState === 'close' && 'error') || 'success'}
					sx={{ textTransform: 'capitalize' }}
				>
					{trainingState === 'close' ? 'closed' : 'open'}
				</Label>
			</TableCell>

			<TableCell align='right'>
				<TableMoreMenu
					open={openMenu}
					onOpen={handleOpenMenu}
					onClose={handleCloseMenu}
					actions={
						<>
							<MenuItem
								onClick={() => {
									dispatch(getTrainingId(row?._id));
									handleCloseMenu();

									navigate(`/dashboard/training/${row?._id}`);
								}}
							>
								<Iconify icon={'bx:detail'} />
								Detail
							</MenuItem>
						</>
					}
				/>
			</TableCell>
		</TableRow>
	);
}
