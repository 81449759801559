import { useState, useEffect } from 'react';
import { paramCase, capitalCase } from 'change-case';

// @mui
import { Box, Container } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

// _mock_
import { _userAbout, _userList } from '../../_mock/_user';

import { userDetail } from '../../api';

// section

import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';

// components
import Page from '../../components/Page';

import { useParams, useLocation } from 'react-router-dom';
import { getUserCookie } from '../../utils/getCookies';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UserProfileEdit() {
	const { pathname } = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	const cookie = getUserCookie();
	const userId = useSelector((state) => state.user.userId);

	const [editDetailData, setEditDetailData] = useState(null);

	const isEdit = pathname.includes('edit');

	useEffect(() => {
		async function fetchDetail() {
			// You can await here
			const response = await userDetail(cookie, userId);
			if (response && response?.data?.data) {
				setEditDetailData(response?.data?.data);
			}
		}
		fetchDetail();
	}, [userId]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1000); // 1000ms = 1 seconds
		return () => clearTimeout(timer);
	}, [editDetailData]);

	return (
		<Page title='User: Edit Profile'>
			<Container>
				{isLoading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress color='info' />
					</Box>
				) : (
					<UserNewEditForm
						isEdit={isEdit}
						userId={userId}
						userData={editDetailData}
					/>
				)}
			</Container>
		</Page>
	);
}
