import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from '../../src/redux/userSlice';

const rootReducer = combineReducers({
	user: userReducer,
	// any other reducers here
});
export const store = configureStore({
	reducer: rootReducer,
});
