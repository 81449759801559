// @mui
import { Link, Card, Typography, CardHeader, Stack, Box } from '@mui/material';
// components

// ----------------------------------------------------------------------

export default function ProfileAbout({ profile }) {
	const { bio, city, firstName, lastName } = profile;

	let text = profile?.interests ? profile?.interests?.toString() : 'no';

	return (
		<Card>
			<CardHeader title='About' />

			<Stack spacing={2} sx={{ p: 3 }}>
				<Typography variant='body1'>{bio}</Typography>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>First name: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{firstName}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Last name: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{lastName}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Lives at: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{city}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Email: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{profile?.user?.email}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>User role: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{profile?.user?.role}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Enable: </b> &nbsp;
					</Box>
					<Typography variant='body1'>
						{profile?.user?.isEnable ? 'Yes' : 'No'}
					</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Interests: </b> &nbsp;
					</Box>
					<Typography variant='body1'>{text}</Typography>
				</Stack>

				<Stack direction='row'>
					<Box sx={{ color: '#9e9e9e' }}>
						<b>Rating: </b> &nbsp;
					</Box>
					<Typography variant='body1'>
						{profile?.rating ? profile?.rating : 0}
					</Typography>
				</Stack>
			</Stack>
		</Card>
	);
}
