import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { getUserCookie } from '../../src/utils/getCookies';

// @mui
import { Grid, Container, Typography } from '@mui/material';
import { verifiedUsers, sportsCount } from '../../src/api';

// sections
import {
	AppWidgetSummary,
	AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
	const cookie = getUserCookie();

	const [users, setUsers] = useState({});

	const [sports, setSports] = useState([]);

	const fetchMyAPI = useCallback(async () => {
		let response = await verifiedUsers(cookie);
		setUsers(response?.data?.data);
	}, [cookie]);

	const fetchSports = useCallback(async () => {
		let response = await sportsCount(cookie);
		setSports(response?.data?.data);
	}, [cookie]);

	useEffect(() => {
		fetchMyAPI();
	}, [fetchMyAPI]);

	const modifiedArray = sports?.map((obj) => {
		return { label: obj?._id, value: obj?.count };
	});

	useEffect(() => {
		fetchSports();
	}, [fetchSports]);

	return (
		<>
			<Helmet>
				<title> Dashboard </title>
			</Helmet>

			<Container maxWidth='xl'>
				<Typography variant='h4' sx={{ mb: 5 }}>
					Hi, Welcome back
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title='Total Verified Users'
							total={users?.verifiedUsers}
							image={true}
							src={'/assets/images/verified.png'}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title='Total Fitners'
							total={users?.fitners}
							color='info'
							image={true}
							src={'/assets/images/fitner.png'}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title='Total Players'
							total={users?.players}
							color='warning'
							image={true}
							src={'/assets/images/player.png'}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title='Total Registered Users'
							total={users?.registeredUsers}
							color='error'
							image={true}
							src={'/assets/images/user.png'}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={8}>
						<AppConversionRates
							title='Interested Sports'
							subheader='(players & fitners)'
							chartData={modifiedArray}
						/>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
