import Api from "../../utils/axios";
const Login = async (email, password) => {
  try {
    return await Api.post(`/auth/login`, {
      email: email,
      password: password,
    });
  } catch (error) {
    console.log("Error....", error);
    return {
      status: 404,
    };
  }
};

export default Login;
