import Api from '../../utils/axios';
const sportsCount = async (token, id) => {
	try {
		return await Api.get(`/admin/sports-count`, {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default sportsCount;
