// @mui
import { Link, Card, Typography, CardHeader, Stack, Box } from "@mui/material";
// components

// ----------------------------------------------------------------------

export default function TrainingAbout({ training }) {
  const {
    description,
    name,
    place,
    schedule,
    eventType,
    price,
    startDate,
    endDate,
    startTime,
    endTime,
    duration,
    players,
  } = training;

  return (
    <Card>
      <CardHeader title="About" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body1">{description}</Typography>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Training Name: </b> &nbsp;
          </Box>
          <Typography variant="body1">{name}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Training Place: </b> &nbsp;
          </Box>
          <Typography variant="body1">{place}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Duration: </b> &nbsp;
          </Box>
          <Typography variant="body1">{duration}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Event Type: </b> &nbsp;
          </Box>
          <Typography variant="body1">{eventType}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Start Date: </b> &nbsp;
          </Box>
          <Typography variant="body1">{startDate}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>End Date: </b> &nbsp;
          </Box>
          <Typography variant="body1">{endDate}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Price: </b> &nbsp;
          </Box>
          <Typography variant="body1">{price}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Start Time: </b> &nbsp;
          </Box>
          <Typography variant="body1">{startTime}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>End Time: </b> &nbsp;
          </Box>
          <Typography variant="body1">{endTime}</Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Fitner Name </b> &nbsp;
          </Box>
          <Typography variant="body1">
            {training?.fitner?.firstName + " " + training?.fitner?.lastName}
          </Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Fitner Contact: </b> &nbsp;
          </Box>
          <Typography variant="body1">
            {training?.fitner?.phoneNumber}
          </Typography>
        </Stack>

        <Stack direction="row">
          <Box sx={{ color: "#9e9e9e" }}>
            <b>Total Players: </b> &nbsp;
          </Box>
          <Typography variant="body1">{players?.length}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
