import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import TrainingsPage from './pages/TrainingsPage';
import { UserList } from './pages/dashboard';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import PaymentCheckout from './pages/PaymentCheckout';
import PaymentFailed from './pages/PaymentFailed';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import ChangePassword from './pages/ChangePassword';

import VerifyResetCode from './pages/VerifyResetCode';
import {
	UserProfile,
	UserProfileEdit,
	TrainingDetail,
} from './pages/dashboard';

import DashboardAppPage from './pages/DashboardAppPage';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function Router() {
	const userId = useSelector((state) => state.user.userId);
	const trainingId = useSelector((state) => state.user.trainingId);

	const routes = useRoutes([
		{
			path: '/dashboard',
			element: <DashboardLayout />,
			children: [
				{ element: <Navigate to='/dashboard/app' />, index: true },
				{ path: 'app', element: <DashboardAppPage /> },

				{ path: 'user', element: <UserList /> },

				{ path: 'trainings', element: <TrainingsPage /> },

				{ path: `training/${trainingId}`, element: <TrainingDetail /> },

				{ path: `user/profile/${userId}`, element: <UserProfile /> },
				{
					path: `user/profile/edit/${userId}`,
					element: <UserProfileEdit />,
				},
			],
		},
		{
			path: 'login',
			element: <LoginPage />,
		},
		{
			path: 'forgot-password',
			element: <ForgotPassword />,
		},
		{
			path: 'verify-code',
			element: <VerifyResetCode />,
		},
		{
			path: 'update-password',
			element: <UpdatePassword />,
		},

		{
			path: 'change-password',
			element: <ChangePassword />,
		},

		{
			path: '/',
			element: <SimpleLayout />,
			children: [
				{ path: '/', element: <Navigate to='/login' />, index: true },
				{
					path: '/',
					element: <Navigate to='/forgot-password' />,
					index: true,
				},

				{ path: '/', element: <Navigate to='/verify-code' />, index: true },

				{
					path: '/',
					element: <Navigate to='/update-password' />,
					index: true,
				},

				{
					path: '/',
					element: <Navigate to='/change-password' />,
					index: true,
				},

				{ path: '404', element: <Page404 /> },
				{ path: '/checkout-success', element: <PaymentCheckout /> },
				{ path: '/checkout-cancel', element: <PaymentFailed /> },

				{ path: '*', element: <Navigate to='/404' /> },
			],
		},
		{
			path: '*',
			element: <Navigate to='/404' replace />,
		},
	]);

	return routes;
}
