import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changePassword } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';

// @mui
import {
	Stack,
	IconButton,
	InputAdornment,
	TextField,
	Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/iconify';
import { getUserCookie } from '../../utils/getCookies';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const cookie = getUserCookie();

	const [showPassword, setShowPassword] = useState(false);

	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [loading, setLoading] = useState(false);

	const ChangePassWordSchema = Yup.object().shape({
		oldPassword: Yup.string().required('Old Password is required'),
		password: Yup.string()
			.min(6, 'Password must be at least 6 characters')
			.required('New Password is required'),
		confirmNewPassword: Yup.string()
			.required('Confirm Password is required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			oldPassword: '',
			password: '',
			confirmNewPassword: '',
		},
		validationSchema: ChangePassWordSchema,

		onSubmit: async (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setTimeout(() => {
				changePassword(cookie, values.oldPassword, values.password)
					.then((res) => {
						if (res?.data?.status === 200 && res?.data?.error === false) {
							disableLoading();
							enqueueSnackbar('Password Updated!', {
								variant: 'success',
							});
							navigate('/dashboard', { replace: true });
						} else {
							disableLoading();
							enqueueSnackbar('Invalid Password!', {
								variant: 'error',
							});
						}
					})
					.catch(() => {
						disableLoading();
						setSubmitting(false);
						setStatus(false);
					});
			}, 1500);
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={3}>
					<TextField
						name='oldPassword'
						placeholder='Old Password'
						value={formik.values.oldPassword}
						onChange={formik.handleChange}
						error={
							formik.touched.oldPassword &&
							Boolean(formik.errors.oldPassword)
						}
						helperText={
							formik.touched.oldPassword && formik.errors.oldPassword
						}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										edge='end'
									>
										<Iconify
											icon={
												showPassword
													? 'eva:eye-fill'
													: 'eva:eye-off-fill'
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						name='password'
						placeholder='New Password'
						value={formik.values.password}
						onChange={formik.handleChange}
						error={
							formik.touched.password && Boolean(formik.errors.password)
						}
						helperText={formik.touched.password && formik.errors.password}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										edge='end'
									>
										<Iconify
											icon={
												showPassword
													? 'eva:eye-fill'
													: 'eva:eye-off-fill'
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						name='confirmNewPassword'
						placeholder='Confirm New Password'
						value={formik.values.confirmNewPassword}
						onChange={formik.handleChange}
						error={
							formik.touched.confirmNewPassword &&
							Boolean(formik.errors.confirmNewPassword)
						}
						helperText={
							formik.touched.confirmNewPassword &&
							formik.errors.confirmNewPassword
						}
						type={showConfirmPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() =>
											setShowConfirmPassword(!showConfirmPassword)
										}
										edge='end'
									>
										<Iconify
											icon={
												showConfirmPassword
													? 'eva:eye-fill'
													: 'eva:eye-off-fill'
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>

				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={{ my: 2 }}
				></Stack>

				<LoadingButton
					fullWidth
					size='large'
					type='submit'
					// disabled={formik.isSubmitting}
					variant='contained'
					// onClick={handleClick}
				>
					{loading ? (
						<span>
							<CircularProgress size='1.5rem' color='inherit' />
						</span>
					) : (
						<Box> Save Changes</Box>
					)}
				</LoadingButton>
			</form>
		</>
	);
}
