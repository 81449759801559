// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import { useEffect } from "react";
import { getUserCookie } from "./utils/getCookies";
import { useLocation, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function App() {
  const { pathname } = useLocation();

  let navigate = useNavigate();
  const token = getUserCookie();
  const isPayment = pathname.includes("checkout-success");
  const isPaymentCancel = pathname.includes("checkout-cancel");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (isPayment) {
      navigate("/checkout-success");
    }
    if (isPaymentCancel) {
      navigate("/checkout-cancel");
    }
  }, [token]);
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
