// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, CardHeader, Stack } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
	width: 20,
	height: 20,
	marginTop: 1,
	flexShrink: 0,
	marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ProfileSocialInfo({ profile }) {
	const SOCIALS = [
		{
			name: 'Twitter',
			icon: <IconStyle icon={'eva:twitter-fill'} color='#1C9CEA' />,
			href: profile?.links?.twitter ? profile?.links?.twitter : '',
		},
		{
			name: 'Instagram',
			icon: (
				<IconStyle icon={'ant-design:instagram-filled'} color='#D7336D' />
			),
			href: profile?.links?.instagram ? profile?.links?.instagram : '',
		},
		{
			name: 'Facebook',
			icon: <IconStyle icon={'eva:facebook-fill'} color='#1877F2' />,
			href: profile?.links?.facebook ? profile?.links?.facebook : '',
		},
	];

	return (
		<Card>
			<CardHeader title='Social' />
			<Stack spacing={2} sx={{ p: 3 }}>
				{SOCIALS.map((link) => (
					<Stack key={link.name} direction='row' alignItems='center'>
						{link.icon}
						<Link
							component='span'
							variant='body2'
							color='text.primary'
							noWrap
						>
							{link.href}
						</Link>
					</Stack>
				))}
			</Stack>
		</Card>
	);
}
