import Api from '../../utils/axios';
const fitnerTrainings = async (token, search) => {
	try {
		return await Api.get(`/admin/trainings?search=${search}`, {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default fitnerTrainings;
