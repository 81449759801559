import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { removeCookies } from '../../../utils/removeCookies';

import {
	Box,
	Divider,
	Typography,
	MenuItem,
	Avatar,
	IconButton,
	Popover,
	Stack,
} from '@mui/material';
// mocks_
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
	{
		label: 'Home',
		icon: 'eva:home-fill',
	},
	{
		label: 'Profile',
		icon: 'eva:person-fill',
	},
	{
		label: 'Settings',
		icon: 'eva:settings-2-fill',
	},
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const { user } = useSelector((state) => state.user);

	const [logout, setLogout] = useState(false);
	const [open, setOpen] = useState(null);
	const navigate = useNavigate();
	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
		setLogout(false);
	};

	const handleCookies = () => {
		removeCookies();
		navigate('/login', { replace: true });
	};

	const handleSettings = () => {
		navigate('/change-password', { replace: true });
	};

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<Avatar src={account.photoURL} alt='photoURL' />
			</IconButton>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				PaperProps={{
					sx: {
						p: 0,
						mt: 1.5,
						ml: 0.75,
						width: 180,
						'& .MuiMenuItem-root': {
							typography: 'body2',
							borderRadius: 0.75,
						},
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant='subtitle2' noWrap>
						{account.displayName}
					</Typography>
					<Typography
						variant='body2'
						sx={{ color: 'text.secondary' }}
						noWrap
					>
						{user?.email ? user?.email : ''}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<Stack>
					<MenuItem onClick={handleSettings}>Change Password</MenuItem>
					<MenuItem onClick={handleCookies}>Logout</MenuItem>
				</Stack>
			</Popover>
		</>
	);
}
