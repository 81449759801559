// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// utils
import cssStylesImage from '../../../utils/cssStylesImage';
// hooks

// components
import MyAvatar from '../../../components/MyAvatar';
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	'&:before': {
		...cssStylesImage().bgBlur({
			blur: 2,
			color: theme.palette.primary.darker,
		}),
		top: 0,
		zIndex: 9,
		content: "''",
		width: '100%',
		height: '100%',
		position: 'absolute',
	},
}));

const InfoStyle = styled('div')(({ theme }) => ({
	left: 0,
	right: 0,
	zIndex: 99,
	position: 'absolute',
	marginTop: theme.spacing(5),
	[theme.breakpoints.up('md')]: {
		right: 'auto',
		display: 'flex',
		alignItems: 'center',
		left: theme.spacing(3),
		bottom: theme.spacing(3),
	},
}));

// ----------------------------------------------------------------------

export default function ProfileCover({ myProfile }) {
	const { firstName, lastName, profile } = myProfile;

	return (
		<RootStyle>
			<InfoStyle>
				<MyAvatar
					sx={{
						mx: 'auto',
						borderWidth: 2,
						borderStyle: 'solid',
						borderColor: 'common.white',
						width: { xs: 80, md: 128 },
						height: { xs: 80, md: 128 },
					}}
					photoUrl={profile}
					firstName={firstName}
				/>
				<Box
					sx={{
						ml: { md: 3 },
						mt: { xs: 1, md: 0 },
						color: 'common.white',
						textAlign: { xs: 'center', md: 'left' },
					}}
				>
					<Typography variant='h4'>
						{firstName + ' ' + lastName}{' '}
					</Typography>
					<Typography sx={{ opacity: 0.72 }}>
						{myProfile?.user?.role}
					</Typography>
				</Box>
			</InfoStyle>
			<Image
				alt='profile cover'
				src={'/assets/images/fitner.svg'}
				sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
			/>
		</RootStyle>
	);
}
