import { capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Tab, Box, Card, Tabs, Container } from '@mui/material';

import useTabs from '../../hooks/useTabs';

import { _userAbout } from '../../_mock/_user';

import { userDetail } from '../../../src/api';

// components
import Page from '../../components/Page';
import Iconify from '../../components/iconify';

// sections
import {
	Profile,
	ProfileCover,
} from '../../../src/sections/@dashboard/profile';

import { getUserCookie } from '../../utils/getCookies';
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { getUserDetail } from '../../redux/userSlice';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UserProfile() {
	const { currentTab, onChangeTab } = useTabs('profile');
	const userId = useSelector((state) => state.user.userId);

	const [userDetailData, setUserDetailData] = useState([]);
	const cookie = getUserCookie();
	const dispatch = useDispatch();

	useEffect(() => {
		async function fetchDetail() {
			// You can await here
			const response = await userDetail(cookie, userId);
			if (response && response?.data?.data) {
				setUserDetailData(response?.data?.data);
				dispatch(getUserDetail(response?.data?.data));
			}
		}
		fetchDetail();
	}, [userId]);

	const PROFILE_TABS = [
		{
			value: 'profile',
			icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
			component: <Profile myProfile={userDetailData} />,
		},
	];

	return (
		<Page title='User: Profile'>
			<Container>
				<Card
					sx={{
						mb: 3,
						height: 280,
						position: 'relative',
					}}
				>
					<ProfileCover myProfile={userDetailData} />
				</Card>

				{PROFILE_TABS.map((tab) => {
					const isMatched = tab.value === currentTab;
					return isMatched && <Box key={tab.value}>{tab.component}</Box>;
				})}
			</Container>
		</Page>
	);
}
