import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ photoUrl, firstName, ...other }) {
	return (
		<Avatar
			src={photoUrl ? photoUrl : '/assets/images/profile.jpg'}
			alt={''}
			color={photoUrl ? 'default' : createAvatar(firstName).color}
			{...other}
		>
			{createAvatar('').firstName}
		</Avatar>
	);
}
