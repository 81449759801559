import Api from '../../utils/axios';
const forgotPassword = async (email) => {
	try {
		return await Api.post(`/auth/forget-password`, {
			email: email,
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default forgotPassword;
