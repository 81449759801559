import Api from '../../utils/axios';
const verifyCode = async (email, otp) => {
	try {
		return await Api.post(`/auth/verify-account`, {
			email: email,
			otp: otp,
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default verifyCode;
