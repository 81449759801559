import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	user: null,
	userList: null,
	userId: '',
	trainingId: '',
	userById: null,
	trainingById: null,
	forgotEmail: null,
};
export const userSlice = createSlice({
	name: 'user', // same in the store
	initialState,
	reducers: {
		getUserData: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.user = action.payload;
		},

		getUserList: (state, action) => {
			state.userList = action.payload;
		},

		getUserId: (state, action) => {
			state.userId = action.payload;
		},

		getTrainingId: (state, action) => {
			state.trainingId = action.payload;
		},

		getUserDetail: (state, action) => {
			state.userById = action.payload;
		},
		getTrainingDetail: (state, action) => {
			state.trainingById = action.payload;
		},

		forgotPasswordEmail: (state, action) => {
			state.forgotEmail = action.payload;
		},
	},
});
// Action creators are generated for each case reducer function
export const {
	getUserData,
	getUserList,
	getUserId,
	getTrainingId,
	getUserDetail,
	getTrainingDetail,
	forgotPasswordEmail,
} = userSlice.actions; // actions
export default userSlice.reducer;
