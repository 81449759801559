import Api from '../../utils/axios';
const enableUser = async (token, id) => {
	try {
		return await Api.patch(
			`/admin/block-user/${id}`,
			{
				isEnable: true,
			},
			{
				headers: {
					Authorization: 'Bearer ' + token, //the token is a variable which holds the token
				},
			}
		);
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default enableUser;
