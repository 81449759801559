import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
// @mui
import {
  Box,
  Card,
  Table,
  Switch,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Typography,
} from "@mui/material";

// hooks
import useTabs from "../../hooks/useTabs";
import useTable, { getComparator, emptyRows } from "../../hooks/useTable";

// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../components/table";
import PopUp from "../../components/popUp"; // pop up for user deletion confirmation

// sections
import { UserTableToolbar, UserTableRow } from "../../sections/@dashboard/user";
import { getUserCookie } from "../../utils/getCookies";

import { usersList, disableUser, enableUser, deleteUser } from "../../api";

// ----------------------------------------------------------------------

const ROLE_OPTIONS = ["all", "player", "fitner"];

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "role", label: "Role", align: "left" },
  { id: "isVerified", label: "Verified", align: "center" },
  { id: "status", label: "Status", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function UserList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { enqueueSnackbar } = useSnackbar();

  const cookie = getUserCookie();

  const [searchTerm, setSearchTerm] = useState("");

  const [tableData, setTableData] = useState([]);

  const [disableData, setDisableData] = useState([]);

  const [enableData, setEnableData] = useState([]);

  const [deletedData, setDeletedData] = useState([]);

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("all");

  const [openDialog, setOpenDialog] = useState(false);

  const [currentUserId, setCurrentUserId] = useState("");
  const { currentTab: filterStatus } = useTabs("all");

  const [selectedValue, setSelectedValue] = useState("all");

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangeFilter = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlDisable = async (id) => {
    const response = await disableUser(cookie, id);
    if (response && response?.data?.data) {
      setDisableData(response?.data?.data);
      enqueueSnackbar("User has been disabled!", {
        variant: "success",
      });
    }
  };

  const handlEnable = async (id) => {
    const response = await enableUser(cookie, id);
    if (response && response?.data?.data) {
      setEnableData(response?.data?.data);
      enqueueSnackbar("User has been enabled!", {
        variant: "success",
      });
    }
  };

  const handleOpenDialog = (id) => {
    setCurrentUserId(id);
    setOpenDialog(true);
  };

  const handlDelete = async (id) => {
    const response = await deleteUser(cookie, id);
    if (response && response?.data?.data) {
      setDeletedData(response?.data?.data);
      enqueueSnackbar("User deleted successfully!", {
        variant: "success",
      });
    }
    setCurrentUserId("");
  };

  useEffect(() => {
    if (cookie) {
      async function fetchData() {
        // You can await here
        const response = await usersList(cookie, searchTerm, selectedValue);
        if (response && response?.data?.data) {
          setTableData(response?.data?.data);
        }
      }
      fetchData();
    }
  }, [searchTerm, selectedValue, disableData, enableData, deletedData]);

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="User: List">
      <Container>
        <Box marginBottom={2}>
          <Typography variant="h5"> Users List</Typography>
        </Box>
        {/* pop Up for delete User Confirmation */}

        <PopUp
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handlDelete={handlDelete}
          currentUserId={currentUserId}
        />
        <Card>
          <Divider />

          <UserTableToolbar
            value={searchTerm}
            onChange={handleChangeSearch}
            filterRole={filterRole}
            optionsRole={ROLE_OPTIONS}
            filterValue={selectedValue}
            filterOnChange={handleChangeFilter}
            searchPlaceHolder="Search User..."
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />
              )}

              <Table size={dense ? "small" : "medium"}>
                <TableHeadCustom
                  order={order}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <UserTableRow
                        key={row._id}
                        row={row}
                        selected={selected.includes(row._id)}
                        handlDisable={handlDisable}
                        handlEnable={handlEnable}
                        handlDelete={handlDelete}
                        handleOpenDialog={handleOpenDialog}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{
                px: 3,
                py: 1.5,
                top: 0,
                position: { md: "absolute" },
              }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== "all") {
    tableData = tableData.filter((item) => item?.user?.role === filterRole);
  }

  return tableData;
}
