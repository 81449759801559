import Api from '../../utils/axios';
const editUser = async (token, id, payLoad) => {
	try {
		return await Api.patch(
			`/admin/update-user/${id}`,
			{
				...payLoad,
			},
			{
				headers: {
					Authorization: 'Bearer ' + token, //the token is a variable which holds the token
				},
			}
		);
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default editUser;
