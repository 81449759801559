import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
	Link,
	Container,
	Typography,
	Stack,
	Box,
	TextField,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import Page from '../components/Page';

import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import VerifyCodeForm from '../sections/auth/verifyCodeForm';
import { useSelector } from 'react-redux';
import { forgotPassword } from '../api';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	height: '100%',
	alignItems: 'center',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyResetCode() {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const forgotEmail = useSelector((state) => state.user.forgotEmail);

	const handleResetCode = async () => {
		enableLoading();

		setTimeout(() => {
			forgotPassword(forgotEmail)
				.then((res) => {
					if (
						res?.data?.status === 200 &&
						res?.data?.data?.role === 'admin'
					) {
						disableLoading();
						enqueueSnackbar('Email successfully sent!', {
							variant: 'success',
						});
					} else {
						disableLoading();
						enqueueSnackbar('Invalid email!', {
							variant: 'error',
						});
					}
				})
				.catch((error) => {
					disableLoading();
					console.error(error);
				});
		}, 1500);
	};

	return (
		<Page title='Verify' sx={{ height: 1 }}>
			<RootStyle>
				<LogoOnlyLayout />

				<Container>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: '18px',
						}}
					>
						<img
							src='/assets/images/share.png'
							width='80'
							height='80'
							alt='sent'
						/>
					</Box>
					<Box
						sx={{
							maxWidth: 480,
							mx: 'auto',
						}}
					>
						<Typography variant='h3' paragraph>
							Request sent successfully!
						</Typography>

						<Typography sx={{ color: 'text.secondary' }}>
							We have emailed you a 6-digit confirmation code, please
							enter the code in below box to verify your email.
						</Typography>
						<Stack spacing={2} marginTop={3}>
							<TextField
								id='email'
								name='email'
								type='email'
								value={forgotEmail}
								disabled={true}
							/>
						</Stack>

						<Box sx={{ mt: 5, mb: 3 }}>
							<VerifyCodeForm />
						</Box>
						{loading ? (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<CircularProgress color='info' />
							</Box>
						) : (
							<Box>
								<Typography variant='body2' align='center'>
									Don’t have a code? &nbsp;
									<Link
										sx={{ cursor: 'pointer' }}
										variant='subtitle2'
										underline='none'
										onClick={() => {
											handleResetCode();
										}}
									>
										Resend code
									</Link>
								</Typography>
							</Box>
						)}
					</Box>
				</Container>
			</RootStyle>
		</Page>
	);
}
