import Api from '../../utils/axios';
const verifiedUsers = async (token) => {
	try {
		return await Api.get(`admin/verified-users`, {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default verifiedUsers;
