import axios from 'axios';

export const basePath = process.env.REACT_APP_HOST_URL;

const API_ROUTE = '/api/v1/';
const Api = axios.create({
	baseURL: basePath + API_ROUTE,
});

export default Api;
