import { useState, useEffect } from "react";

// @mui
import {
  Box,
  Card,
  Table,
  Switch,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Typography,
} from "@mui/material";
// routes
// hooks
import useTable, { getComparator, emptyRows } from "../hooks/useTable";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../components/table";
// sections
import {
  UserTableToolbar,
  TrainingTableRow,
} from "../sections/@dashboard/user";
import { getUserCookie } from "../utils/getCookies";

import { fitnerTrainings } from "../api";

// ----------------------------------------------------------------------

const TABLE = [
  { id: "name", label: "Training Name", align: "left" },
  { id: "price", label: "Price", align: "left" },
  { id: "place", label: "Place", align: "left" },
  { id: "duration", label: "Duration", align: "left" },
  { id: "eventType", label: "Event", align: "center" },
  { id: "trainingState", label: "Status", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function TrainingsPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,

    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const cookie = getUserCookie();

  const [searchTerm, setSearchTerm] = useState("");

  const [trainingData, setTrainingData] = useState([]);

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("all");

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (cookie) {
      async function fetchTrainings() {
        // You can await here
        const response = await fitnerTrainings(cookie, searchTerm);
        if (response && response?.data?.data) {
          setTrainingData(response?.data?.data);
        }
      }
      fetchTrainings();
    }
  }, [searchTerm]);

  const dataFiltered = applySortFilter({
    trainingData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole);

  return (
    <Page title="Training: List">
      <Container>
        <Box marginBottom={2}>
          <Typography variant="h5"> Trainings List</Typography>
        </Box>

        <Card>
          <Divider />
          <UserTableToolbar
            noFilter
            value={searchTerm}
            onChange={handleChangeSearch}
            searchPlaceHolder={"Search Training..."}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={trainingData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      trainingData.map((row) => row._id)
                    )
                  }
                />
              )}

              <Table size={dense ? "small" : "medium"}>
                <TableHeadCustom
                  order={order}
                  headLabel={TABLE}
                  rowCount={trainingData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      trainingData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {trainingData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TrainingTableRow
                        key={row._id}
                        row={row}
                        selected={selected.includes(row._id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      trainingData.length
                    )}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{
                px: 3,
                py: 1.5,
                top: 0,
                position: { md: "absolute" },
              }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  trainingData,
  comparator,
  filterStatus,
  filterRole,
}) {
  const stabilizedThis = trainingData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  trainingData = stabilizedThis.map((el) => el[0]);

  if (filterStatus !== "all") {
    trainingData = trainingData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== "all") {
    trainingData = trainingData.filter(
      (item) => item?.user?.role === filterRole
    );
  }

  return trainingData;
}
