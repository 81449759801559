import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Box } from '@mui/material';
// components
// sections
import ChangePasswordForm from '../sections/auth/ChangePasswordForm';
import Page from '../components/Page';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
	const navigate = useNavigate();

	return (
		<Page title='Update Password'>
			<Container maxWidth='sm'>
				<Box
					sx={{
						maxWidth: 480,
						mx: 'auto',
					}}
				>
					<StyledContent>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<img
								src='/assets/images/update.png'
								width='80'
								height='80'
								alt='sent'
							/>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography variant='h3' paragraph>
								Change your password!
							</Typography>
						</Box>

						<ChangePasswordForm />

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '10px',
							}}
						>
							<Link
								sx={{
									cursor: 'pointer',
								}}
								component={''}
								variant='subtitle2'
								onClick={() => navigate('/dashboard')}
							>
								Back to dashboard?
							</Link>
						</Box>
					</StyledContent>
				</Box>
			</Container>
		</Page>
	);
}
