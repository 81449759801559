import Api from '../../utils/axios';
const usersList = async (token, search, role) => {
	try {
		return await Api.get(`/admin/users?search=${search}&role=${role}`, {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default usersList;
