import Api from '../../utils/axios';
const deleteUser = async (token, id) => {
	try {
		console.log('token...', token);
		return await Api.patch(`/admin/delete-user/${id}`, {},
        {
			headers: {
				Authorization: 'Bearer ' + token, //the token is a variable which holds the token
			},
		});
	} catch (error) {
		return {
			status: 404,
		};
	}
};

export default deleteUser;
